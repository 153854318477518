import React from "react";
import theme from "theme";
import { Theme, Link, Text, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"policy"} />
		<Helmet>
			<title>
				Privacy Policy Ternoxgames.com
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/back.jpg?v=2022-01-08T11:10:00.783Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Menuu>
			<Override slot="text">
				<Link href="https://ternoxgames.com/" color="#000000" text-decoration-line="initial">
					Ternoxgames.com
				</Link>
			</Override>
		</Components.Menuu>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			background="--color-darkL1"
		>
			<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0 0 0">
				Privacy policy ternoxgames.com and games from Ternox{"\n\n"}
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				<br />
				I am Ternox – solo developer and publisher. I committed to protecting and respecting your privacy.{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					I don't collect personal data and we don't use cookies to collect personally identifiable information about you
				</Strong>
				.
				<br />
				<br />
				I can receive information from you directly or take it from public sources, but only for direct contact with you on a one-time basis.
				<br />
				<br />
				I don't know who buys my games, and I can only judge from the non-anonymous feedback I receive (e.g. on the Steam or Microsoft Store game page).
				<br />
				<br />
				I can also email you if you give it in the feedback form.{" "}
			</Text>
			<Components.Knopa font="20px sans-serif" href="/">
				Main page
			</Components.Knopa>
		</Section>
	</Theme>;
});